<template>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" />
  <link
    href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap"
    rel="stylesheet"
  />
  <router-view />
</template>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap");
@import "@/styles/smart-grid.scss";
html {
  font-size: 0.521vw;
  scroll-behavior: smooth;
  user-select: none;
  @include sm-block() {
    font-size: 2.564vw;
    user-select: none;
    scroll-behavior: smooth;
    font-family: "Inter", sans-serif;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
</style>
