<template>
<div class="centered-text">Перенаправление...</div>
</template>

<script>
export default {
  created() {
    // Перенаправление пользователя на другой сайт при создании компонента
    window.location.href = 'http://www.codeshop.kz/'; // Замените на URL сайта, на который нужно перенаправить пользователя
  }
}
</script>

<style scoped>
.centered-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  font-size: 5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>